@font-face {
	font-family: "wonderfull";
	src: url("\.\/assets\/fonts\/Wonderfull\/Wonderfull\.woff2") format("woff2"),
		url("\.\/assets\/fonts\/Wonderfull\/Wonderfull\.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Keys of Paradise";
	src: url("\.\/assets\/fonts\/KeysOfParadise\/KeysOfParadise\.woff2") format("woff2"),
		url("\.\/assets\/fonts\/KeysOfParadise\/KeysOfParadise\.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}